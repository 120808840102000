import DefaultEnum from '@/enums/DefaultEnum';

  

export default {
    data() {
        return {
            isClicked: DefaultEnum.NAO,
            loading: DefaultEnum.SIM,
            texto: "",
            logoTipo: process.env.VUE_APP_LOGO,
            DefaultEnum: DefaultEnum,
            planoEscolhido: {
                servicos: []
            },
            globCliente: this.$secureLS.get('ls_customer') ?? null,
            globClienteId: this.$secureLS.get('ls_customer').id ?? null,
            globCotacao: this.$secureLS.get('ls_quotation') ?? null,
            globCotacaoId: this.$secureLS.get('ls_quotation').id ?? null,
        }
    },
    watch: {
    },
    methods: {
        alert(titulo, message, tipo) {
            return this.$swal.fire(
                titulo,
                message,
                tipo,
            );
        },
        verificacao(tipoBusca) {
            this.$swal({
              title: 'Uma nova cotação foi detectada',
              text: 'Deseja continuar na nova cotação ?  Esse processo não poderá ser desfeito',
              type: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Permanecer na atual',
              confirmButtonText: 'Continuar na nova',
              showCloseButton: true,
              showLoaderOnConfirm: true
            }).then((result) => {
              if(result.value) {

                switch(tipoBusca){
                    case 'cliente':
                        this.loading = DefaultEnum.SIM;

                        this.clearLS();
                        this.verificaClienteComp();
                        if(this.getCompUrl() && this.getIdUrl()){
                            this.setCurrentComp(this.getCompUrl());
                        }
                        // this.$swal('Nova cotação carregada com sucesso', '', 'success')
                    break;

                    case 'cotacao':
                        this.loading = DefaultEnum.SIM;
                        this.clearLS();
                        this.verificaCotacaoComp();
                        
                        if(this.getCompUrl() && this.getIdUrl()){
                            if(this.getCompUrl() == 'beneficios'){
                                this.setCurrentComp('planos');
                            } else {
                                this.setCurrentComp(this.getCompUrl());
                            }

                        }
                        // this.$swal('Nova cotação carregada com sucesso', '', 'success')
                    break;

                    default:
                    break;
                }
              } 

            })
          },
        clicked(){
            if (!this.isClicked) {
                this.isClicked = true;
            }
        },
        // remove caracteres especiais de string
        limpaString(value) {
            if (value) {
                return value.toString().replace(/[^0-9]+/g, '');
            }
        },
        //Validando o retorno do envio
        validarRetorno(titulo, data) {
            var mensagens = [data.message];
            var errors = data.errors || null;

            if (errors != null) {
                for (var index in errors) {
                    mensagens.push(errors[index].join(', '));
                }
            }

            this.$swal.fire(titulo, mensagens.join('<br />'), 'error');
        },
        // converte o fuckin input text de data para formato db
        converteData(date) {
            if (date) {
                let part = date.toString().split('/')
                return `${part[2]}-${part[1]}-${part[0]}`
            }
        },
        validarCpf(cpf) {
            var soma = 0;
            var resto;
            var strCPF = cpf.replace(/[^0-9]+/g, '');
            if (strCPF === '00000000000') {
                this.$swal.fire('Erro ao validar CPF', 'O CPF informado e inválido.', 'error')
                return false;
            }
            for (var i = 1; i <= 9; i++) {
                soma += parseInt(strCPF.substring(i - 1, i)) * (11 - i);
            }
            resto = soma * 10 % 11;
            if (resto === 10 || resto === 11) {
                resto = 0;
            }
            if (resto !== parseInt(strCPF.substring(9, 10))) {
                this.$swal.fire('Erro ao validar CPF', 'O CPF informado e inválido.', 'error')
                return false;
            }
            soma = 0;
            for (var _i = 1; _i <= 10; _i++) {
                soma = soma + parseInt(strCPF.substring(_i - 1, _i)) * (12 - _i);
            }
            resto = soma * 10 % 11;
            if (resto === 10 || resto === 11) {
                resto = 0;
            }
            if (resto !== parseInt(strCPF.substring(10, 11))) {
                this.$swal.fire('Erro ao validar CPF', 'O CPF informado e inválido.', 'error')
                return false;
            }
            return true;
        },
        iniLoading() {
            this.backToTop()
            this.loading = DefaultEnum.SIM
            // this.$store.commit('UPDATE_LOADING', DefaultEnum.SIM);
        },
        stopLoading() {
            this.backToTop()
            this.loading = DefaultEnum.NAO
            
            this.$store.commit('UPDATE_LOADING', DefaultEnum.NAO);
        },
        backToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        },
        isObjectFilled(obj) {
            return Object.values(obj).every(value => value !== null && value !== undefined && value !== '');
        },
        apenasNumeros(valor) {
            return valor.replace(/[^0-9]/g, '');
        },
        removeCaracteresEspeciais(valor) {
            if (!valor) return "";
            // Remove todos os caracteres que não sejam letras (incluindo acentos), números ou espaços
            return valor.replace(/[^A-Za-zÀ-ÿ0-9\s]/g, '');
        },
        getCurrentComp() {
            return this.$secureLS.get('ls_current_comp').comp;
        },
        setCurrentComp(comp) { 
            this.$store.dispatch('updateComponent', {
                comp: comp,
            });
        },
        getUrl(){
            return this.$route.path.split('/').filter(Boolean); // Divide e remove strings vazias
        },
        getCompUrl(){
            let component = null;

            // Verifica se há ao menos um segmento após o primeiro "/"
            if (this.getUrl().length >= 1) {
                component = this.getUrl()[0];
              }

            return component
        },
        clearLS(){
            this.$secureLS.set('ls_quotation', null);
            this.$secureLS.set('ls_customer', null);
        },
        getIdUrl(){
            let id = null;

            if (this.getUrl().length >= 1 && this.getUrl()[1]) {
                id = this.getUrl()[1];
            }

            return id
        },
        async verificaClienteComp(){
            try {
                const cliente = await this.$http.get("/v1/cliente/" + this.getIdUrl());
                this.$secureLS.set('ls_customer', cliente.data.data);

            } catch(e){
                this.$swal.fire('Opss!', 'Não foi possível localizar seus dados.', 'error');
            }
        },
        async verificaCotacaoComp(){
            try {
                const cotacao = await this.$http.get("/v1/veiculo-cotacao/cotacao/" + this.getIdUrl());
                this.$secureLS.set('ls_quotation', cotacao.data.data);
                this.$secureLS.set('ls_customer', cotacao.data.data.veiculo.cliente);
                
            } catch(e){
                this.$swal.fire('Opss!', 'Não foi possível localizar seus dados. Tente novamente.', 'error');
            }

        },
        async getDadosCotacao(cotacaoId = null) {
            try {
                const response = await this.$http.get('/v1/veiculo-cotacao/cotacao/' + cotacaoId ?? this.globCotacaoId, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                    },
                });

                if (response.data) {
                    return response.data.data;
                }

            } catch (e) {
                // this.$swal.fire('Opss!', 'Não foi possível localizar sua cotação. Tente novamente.', 'error');
                clearInterval(this.intervalCotacao);
            }
        },
        async verificaCliente(id) {
            try {
                await this.$http.get("/v1/cliente/" + this.globClienteId ?? id);

            } catch (err) {
                this.$swal.fire('Opss!', 'Um erro inesperado aconteceu. Tente novamente.', 'error');
            }
        },
        async verificaCotacao() {
            try {
                const response = await this.$http.get('/v1/veiculo-cotacao/cotacao/' + this.globCotacaoId, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                    },
                });

                if (response.data) {
                    return response.data.data;
                }

            } catch (e) {
                this.$swal.fire('Opss!', 'Não foi possível localizar sua cotação. Atualize a página e tente novamente.', 'error');
            }
        },
        async getBoletoIntegra(id) {
            try {
                const response = await this.$http.get('/v1/pagamento/integra/transacao/get-boleto/' + id, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                    },
                });
                return response;
            } catch (e) {
                return;
            }
        }
    },
};