<template>
    <div class="row justify-content-center">
        <div class="col-12 w-100">
            <!-- Stepper -->
            <StepperComp />
            <!-- / Stepper -->

            <!-- Loading -->
            <LoadingDivComp v-show="loading" />
            <!-- Loading -->

            <div class="mb-1" v-show="!loading">

                <!-- Opcoes -->
                <div class="container mt-3 mb-1 pb-2" v-show="mostrarOpcoes == DefaultEnum.SIM">
                    <h5 class="font-weight-bold mb-4">Escolha seu meio de pagamento</h5>

                    <div class="form__radio mt-3" @click="toggleOpcoes(DefaultEnum.CARTAO_CREDITO)">
                        <label for="credit-card">
                            <i class="far fa-credit-card" style="color: #919191;"></i>Cartão de crédito
                        </label>
                    </div>

                    <div class="form__radio mt-3" @click="toggleOpcoes(DefaultEnum.PIX)">
                        <label for="pix">
                            <i class="fas fa-qrcode" style="color: #919191;"></i>PIX
                        </label>
                    </div>

                    <div class="form__radio mt-3 mb-5" @click="toggleOpcoes(DefaultEnum.BOLETO)" v-if="meioPagamento === integrapayEnv">
                        <label for="bill">
                            <i class="fas fa-file-invoice-dollar" style="color: #919191;"></i>Boleto
                        </label>
                    </div>
                </div>
                <!-- Opcoes -->

                <!-- Métodos -->
                <div class="row justify-content-center" v-show="mostrarOpcoes == DefaultEnum.SIM">
                    <!-- CD -->
                    <form id="paymentCreditCard" class="w-100" ref="formcheckout" v-show="paymentCreditCard === DefaultEnum.SIM">

                        <!-- Credit Card From HTML -->
                        <div class="card-form">
                            <div class="card-list">
                                <div class="card-item" v-bind:class="{ '-active' : isCardFlipped }">
                                <div class="card-item__side -front">
                                    <div class="card-item__focus" v-bind:class="{'-active' : focusElementStyle }" v-bind:style="focusElementStyle" ref="focusElement"></div>
                                    <div class="card-item__cover">
                                        <img src="assets/images/credit-card/cartao-padrao.jpg" class="card-item__bg" v-if="!frontalCartao">
                                        <img :src="frontalCartao" class="card-item__bg" v-if="frontalCartao">
                                    </div>
                                    
                                    <div class="card-item__wrapper">
                                    <div class="card-item__top">
                                        <img src="assets/images/credit-card/chip.png" class="card-item__chip">
                                        <div class="card-item__type">
                                        <transition name="slide-fade-up">
                                            <img v-bind:src="'./assets/images/credit-card/' + getCardType + '.png'" v-if="getCardType" v-bind:key="getCardType" alt="" class="card-item__typeImg">
                                        </transition>
                                        </div>
                                    </div>
                                    <label for="cardNumber" class="card-item__number" ref="cardNumber">
                                        <template v-if="getCardType === 'amex'">
                                        <span v-for="(n, $index) in amexCardMask" :key="$index">
                                        <transition name="slide-fade-up">
                                            <div
                                            class="card-item__numberItem"
                                            v-if="$index > 4 && $index < 14 && cardNumber.length > $index && n.trim() !== ''"
                                            >*</div>
                                            <div class="card-item__numberItem"
                                            :class="{ '-active' : n.trim() === '' }"
                                            :key="$index" v-else-if="cardNumber.length > $index">
                                            {{cardNumber[$index]}}
                                            </div>
                                            <div
                                            class="card-item__numberItem"
                                            :class="{ '-active' : n.trim() === '' }"
                                            v-else
                                            :key="$index + 1"
                                            >{{n}}</div>
                                        </transition>
                                        </span>
                                        </template>

                                        <template v-else>
                                        <span v-for="(n, $index) in otherCardMask" :key="$index">
                                            <transition name="slide-fade-up">
                                            <div
                                                class="card-item__numberItem"
                                                v-if="$index > 4 && $index < 15 && cardNumber.length > $index && n.trim() !== ''"
                                            >*</div>
                                            <div class="card-item__numberItem"
                                                :class="{ '-active' : n.trim() === '' }"
                                                :key="$index" v-else-if="cardNumber.length > $index">
                                                {{cardNumber[$index]}}
                                            </div>
                                            <div
                                                class="card-item__numberItem"
                                                :class="{ '-active' : n.trim() === '' }"
                                                v-else
                                                :key="$index + 1"
                                            >{{n}}</div>
                                            </transition>
                                        </span>
                                        </template>
                                    </label>
                                    <div class="card-item__content">
                                        <label for="cardName" class="card-item__info" ref="cardName">
                                        <div class="card-item__holder">Títular do cartão</div>
                                                <transition name="slide-fade-up">
                                                    <div class="card-item__name" v-if="cardName.length" key="1">
                                                    <transition-group name="slide-fade-right">
                                                    <!-- eslint-disable--> <span class="card-item__nameItem" v-for="(n, $index) in cardName.replace(/\s\s+/g, ' ')" v-if="$index === $index" v-bind:key="$index + 1">{{n}}</span>
                                                    </transition-group>
                                                    </div>
                                                    <div class="card-item__name" v-else key="2">Nome do titular</div>
                                                </transition>
                                                </label>
                                            <div class="card-item__date" ref="cardDate">
                                                <label for="cardMonth" class="card-item__dateTitle">Validade</label>
                                                <label for="cardMonth" class="card-item__dateItem">
                                                    <transition name="slide-fade-up">
                                                    <span v-if="cardMonth" v-bind:key="cardMonth">{{cardMonth}}</span>
                                                    <span v-else key="2">MM</span>
                                                    </transition>
                                                </label>
                                                <label for="cardYear" class="card-item__dateItem">
                                                    <transition name="slide-fade-up">
                                                    <span v-if="cardYear" v-bind:key="cardYear">/{{String(cardYear).slice(2,4)}}</span>
                                                    <span v-else key="2">AA</span>
                                                    </transition>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-item__side -back">
                                    <div class="card-item__cover">
                                        <img src="assets/images/credit-card/cartao-padrao.jpg" class="card-item__bg" v-if="!traseiraCartao">
                                        <img :src="traseiraCartao" class="card-item__bg" v-if="traseiraCartao">
                                    </div>
                                    <div class="card-item__band"></div>
                                    <div class="card-item__cvv">
                                        <div class="card-item__cvvTitle">CVV</div>
                                        <div class="card-item__cvvBand">
                                        <span v-for="(n, $index) in cardCvv" :key="$index">
                                            *
                                        </span>

                                    </div>
                                        <div class="card-item__type">
                                            <img v-bind:src="'./assets/images/credit-card/' + getCardType + '.png'" v-if="getCardType" class="card-item__typeImg">
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <div class="card-form__inner">
                                <div class="card-input" v-if="false">
                                    <label for="form-checkout__identificationType" class="">CPF</label>
                                    <input name="cpf"
                                        id="form-checkout__identificationType" class="card-input__input" type="tel" placeholder=" "
                                        v-model="cpf"
                                        v-mask="'###.###.###-##'" />
                                </div>
                                <div class="card-input" v-if="false">
                                    <label for="form-checkout__email" class="">E-mail</label>
                                    <input name="email" id="form-checkout__email" class="card-input__input"
                                        type="email" placeholder=" " 
                                        v-model="email"
                                        />
                                </div>
                                <div class="card-input">
                                    <label for="cardNumber" class="card-input__label">Número do cartão</label>
                                        <input type="text" id="cardNumber" name="cardNumber" class="card-input__input" 
                                        v-mask="generateCardNumberMask" 
                                        v-model="cardNumber" 
                                        v-on:focus="focusInput" 
                                        v-on:blur="blurInput" 
                                        v-on:change="buscaParcelamento"
                                        data-ref="cardNumber" autocomplete="off">
                                </div>

                                <div class="card-input">
                                    <label for="cardName" class="card-input__label">Nome do titular do cartão</label>
                                        <input type="text" id="cardName" name="cardName" class="card-input__input" 
                                        v-model="cardName" 
                                        v-on:focus="focusInput" 
                                        v-on:blur="blurInput" 
                                        data-ref="cardName">
                                </div>

                                <div class="card-form__row mt-3 mb-3">
                                    <div class="card-form__col">
                                        <label for="cpf" class="card-input__label">CPF</label>
                                        <input type="text" id="cpf" name="cpf" class="card-input__input" 
                                        v-model="cpf" 
                                        v-mask="'###.###.###-##'">
                                    </div>

                                    <div class="card-form__col">
                                        <label for="celular" class="card-input__label">Celular</label>
                                        <input type="text" id="celular" name="celular" class="card-input__input" 
                                        v-model="celular" 
                                        v-mask="'55 (##) # #### ####'">
                                    </div>
                                </div>

                                <div class="card-form__row">
                                    <div class="card-form__col">
                                        <div class="card-form__group">
                                            <label for="cardMonth" class="card-input__label">Validade</label>
                                            <select class="card-input__input -select" id="cardMonth" name="cardMonth" 
                                            v-model="cardMonth" 
                                            v-on:focus="focusInput" 
                                            v-on:blur="blurInput" 
                                            data-ref="cardDate">
                                            <option value="" disabled selected>Mês</option>
                                            <option 
                                                v-for="n in 12" 
                                                :value="n < 10 ? '0' + n : n" 
                                                :disabled="n < minCardMonth" 
                                                :key="n"
                                            >
                                                {{ n < 10 ? '0' + n : n }}
                                            </option>
                                        </select>
                                            <select class="card-input__input -select" id="cardYear" name="cardYear" 
                                            v-model="cardYear" 
                                            v-on:focus="focusInput" 
                                            v-on:blur="blurInput" 
                                            data-ref="cardDate">
                                            <option value="" disabled selected>Ano</option>
                                            <option v-bind:value="$index + minCardYear" v-for="(n, $index) in 12" v-bind:key="n">
                                                {{$index + minCardYear}}
                                            </option>
                                        </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="card-form__row mt-4">
                                    <div class="card-form__col">
                                        <div class="card-input">
                                        <label for="cardCvv" class="card-input__label">Parcelas</label>


                                        <select class="card-input__input -select" v-if="meioPagamento == mercadopagoEnv" v-model="parcelaSelecionada" name="parcela" @change="parcelaSelecionadaMP()">
                                            <option v-if="parcelasMP != null" v-for="(parcela, index) in parcelasMP" :key="index" :value="index">
                                                {{ parcela.recommended_message }}
                                            </option>
                                        </select>

                                                                                        
                                        <select class="card-input__input -select" v-if="meioPagamento == integrapayEnv" v-model="parcelas">
                                            <option v-for="index in 12" :value="index">{{ index }}</option>
                                        </select>

                                        </div>
                                    </div>
                                    <div class="card-form__col -cvv">
                                        <div class="card-input">
                                        <label for="cardCvv" class="card-input__label">CVV</label>
                                        <input type="password" class="card-input__input" id="cardCvv" name="cardCvv" 
                                        v-mask="'###'" maxlength="3" 
                                        v-model="cardCvv" 
                                        v-on:focus="flipCard(true)" 
                                        v-on:blur="flipCard(false)" autocomplete="off">
                                        </div>
                                    </div>
                                </div>
                                
                                <input type="hidden" name="cardToken">
                                <input @click="pagamento($event)" type="button" name="pagar" class="card-form__button right" value="Pagar" />
                            </div>
                        </div>
                        <!-- END Credit Card From HTML -->
                        
                    </form> 
                    <!-- CD -->

                    <!-- B -->
                    <div id="paymentBill" class="col-12 col-md-6 my-5" v-if="this.meioPagamento === integrapayEnv && this.paymentBill == DefaultEnum.SIM">
                        <p class="font-weight-light">Escolha a data de vencimento do boleto e clique no botão abaixo.</p>
                        <div class="input-container">
                            <input class="input" type="date" name="data_vencimento" @input="verificaDataBoleto()" v-model="data_vencimento">
                            <label for="data_vencimento" class="placeholder">Data de vencimento *</label>
                        </div>
                        <div class="box-container mt-2 font-weight-light pointer pagamento-style" @click="pagamento" v-if="boletoLiberado == DefaultEnum.SIM">
                            <i class="fas fa-file-invoice-dollar"></i>
                            Gerar Boleto
                        </div>
                    </div>
                    <!-- B -->

                    <!-- P -->
                    <div id="paymentBill" class="col-12 col-md-6 my-5" v-if="paymentPix === DefaultEnum.SIM && tentouPagamento == DefaultEnum.NAO">
                        <p class="font-weight-light">Clique no botão abaixo para gerar seu QRCode.</p>
                        <div class="box-container mt-2 font-weight-light pointer pagamento-style" @click="pagamento">
                            <i class="fas fa-qrcode"></i>
                            Gerar QR Code
                        </div>
                    </div>
                    <!-- P -->
                </div>
                <!--  -->

                <!-- Concluido -->
                <div class="row justify-content-center" v-show="PaymentSuccess === DefaultEnum.SIM">
                    <div class="col-sm-12 col-md-6">
                        <div class="mt-3">
                            <div>
                                <h4 class="font-weight-bold text-center mt-2">
                                    Pagamento <span class="text-success">realizado</span> com
                                    sucesso!
                                </h4>
                                <div class="text-center mt-4">
                                    <img src="assets/images/success-payment.svg" alt="" class="img-fluid" style="width: 60%; height: 60%">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--  -->

                <!-- Negado -->
                <div class="row justify-content-center pagamentoNegado" v-show="PaymentSuccess === DefaultEnum.NAO">
                    <div class="col-sm-12 col-md-8">
                        <div class="mt-3">
                            <div>
                                <h4 class="font-weight-bold text-center mt-2">
                                    Ops! Algo deu errado no pagamento.
                                </h4>
                                
                                <div class="text-center mt-4">
                                    <img src="assets/images/failure-payment.svg" alt="" class="img-fluid" style="width: 50%; height: 50%">
                                </div>
                            </div>
                            
                            <div class="mt-5 d-flex justify-content-center">
                                <div class="text-center">
                                    <p class="font-weight-bold">Verifique se seus dados estão corretos e tente novamente.</p>
                                    <div class="tente-novamente d-flex justify-content-center align-items-center">
                                        <a href="#" @click="tentarNovoPagamento">Clique aqui para tentar novamente</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--  -->

                <!-- Tentar novo pagamento -->
                <div class="row justify-content-center mt-4" v-show="tentouPagamento === DefaultEnum.SIM && PaymentSuccess == null">
                    <div class="col-sm-12 col-md-6">
                        <div class="box-container mt-3">
                            <div class="mt-3 text-center">
                                <p class="font-weight-bold">Não conseguiu fazer o pagamento?</p>
                            </div>

                            <div class="tente-novamente d-flex justify-content-center align-items-center">
                                <a href="#" @click="tentarNovoPagamento">Clique aqui para tentar novamente</a>
                            </div>
                        </div>
                    </div>
                </div>
                <!--  -->

                <!-- P pix -->
                <div class="row justify-content-center">
                    <div class="col-sm-12 col-md-6">
                        <div class="box-container mt-3" v-if="qrCodeGerado === DefaultEnum.SIM && qrCodeImage !== null && PaymentSuccess !== DefaultEnum.SIM">
                            <div>
                                <h6 class="font-weight-bold text-center mt-2">
                                    QRCode gerado com sucesso!
                                </h6>

                                <p>{{ formatPix }}</p>
                                
                                <div class="text-center mt-1" v-if="qrCodeImage">
                                    <img :src="'data:image/jpeg;base64,' + qrCodeImage" alt="QRCode" style="width: 60%; height: 60%">
                                </div>

                                <div class="mt-2 d-flex justify-content-center">
                                    <div class="text-center">
                                        <span>Você também pode copiar a chave abaixo e fazer o pagamento no seu banco digital.</span><br>
                                        <button class="button" @click="copiarChavePix()">Copiar a chave pix</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="box-container mt-3" v-if="qrCodeGerado === DefaultEnum.SIM && qrCodeImage === null">
                            <h6 class="font-weight-bold text-center mt-2">
                                 Não foi possível gerar seu QRCode. Tente novamente.
                            </h6>
                            <img src="@/assets/svg/ops.svg" alt="" width="200px" height="230px">

                            <!-- <div class="mt-2 d-flex justify-content-center" v-if="false">
                                <div class="text-center">
                                    <button class="button" @click="pagamento()">Gerar novo QRCode</button>
                                </div>
                            </div> -->

                        </div>
                        
                    </div>
                </div>
                <!--  -->

                <!-- P boleto -->
                <div class="row justify-content-center" v-show="paymentBill === DefaultEnum.SIM && linkBoleto !== null">
                    <div class="col-sm-12 col-md-6">
                        <div class="box-container mt-3">
                            <div>
                                <h4 class="font-weight-bold text-center mt-2">
                                    Seu boleto foi gerado com sucesso!
                                </h4>
                                
                                <div class="text-center mt-4">
                                    <img src="@/assets/svg/payment.svg" alt="" class="img-fluid" style="width: 50%; height: 50%">
                                </div>
                            </div>
                            
                            <div class="mt-5 d-flex justify-content-center">
                                <div class="text-center">
                                    <p class="">Após o pagamento e confirmação do boleto. Entre em contato com seu consultor.</p>
                                    <span class="font-weight-bold">Link boleto:</span>
                                    <a :href="linkBoleto" class="ml-2" target="_blank">Clique aqui</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--  -->

                <!-- Resumo -->
                <div class="row justify-content-center mt-4" v-show="this.cotacao_pago === 0 || PaymentSuccess === DefaultEnum.NAO">
                    <div class="col-sm-12 col-md-6">
                        <div class="box-container">
                            <div class="col-sm-12 col-md-12">
                                <img :src="logoTipo" style="width: 150px;">
                            </div>

                            <div class="mt-3">
                                <p>Falta pouco para darmos inicio à realização do seu grande sonho!</p>
                            </div>

                            <div class="d-flex justify-content-center" v-if="valorAdesao != 0">
                                <div class="text-left">
                                    <h6 class="font-weight-bold">Valor total:</h6>
                                    <span class="valor-simbolo">R$</span>
                                    <span class="valor-plano">{{ valorTotal ? valorTotal : valorAdesao | toCurrency(false) }}</span>
                                </div>
                            </div>

                            <div class="text-center mt-4 mb-1">
                                <span class="font-weight-semibold mb-2">
                                    Compra 100% segura
                                </span>
                                <img src="/img/cartoes.png" class="center">
                            </div>
                        </div>
                    </div>
                </div>
                <!--  -->

            </div>
            <!-- /Box container -->
        </div>

        <!--  -->
        <div class="d-flex justify-content-between w-100 mt-4 px-3">
            <button :disabled="this.isClicked" :class="{ 'clicked': isClicked }" @click="back()" type="button" name="prev" class="prev left">
                <i class="fa-solid fa-arrow-left-long fa-1x"></i> 
            </button>

            <button :disabled="this.isClicked" :class="{ 'clicked': isClicked }" @click="nextpage()" type="button" name="next" class="next right" v-show="PaymentSuccess === DefaultEnum.SIM">
                <i class="fa-solid fa-arrow-right-long fa-1x"></i>
            </button>
        </div>
        <!--  -->

    </div>

</template>

<script>
import DefaultEnum from "@/enums/DefaultEnum";
import StepperComp from '../parts/StepperComp.vue';
import LoadingDivComp from '../parts/LoadingDivComp.vue';

/* eslint-disable */
export default {
    components: {
        StepperComp,
        LoadingDivComp
    },

    data() {
        return {
            DefaultUrl: process.env.VUE_APP_DEFAULT_URL,
            DefaultEnum: DefaultEnum,
            cpf: "",
            celular: "",
            email: "",
            integrapayEnv: process.env.VUE_APP_INTEGRAPAY,
            mercadopagoEnv: process.env.VUE_APP_MERCADO_PAGO,
            frontalCartao: process.env.VUE_APP_FUNDO_CARTAO,
            traseiraCartao: process.env.VUE_APP_TRASEIRA_CARTAO,
            cardName: "",
            cardNumber: "",
            cardMonth: "",
            cardYear: "",
            cardCvv: "",
            cardToken: "",
            instanciaMP: null,
            meioPagamento: null,
            paymentCreditCard: null,
            paymentPix: null,
            paymentBill: null,
            PaymentSuccess: null,
            PaymentId: null,
            PaymentTypeId: null,
            PaymentMethodId: null,
            parcelas: 1,
            parcelasMP: {},
            parcelaSelecionada: null,
            minCardYear: new Date().getFullYear(),
            amexCardMask: "#### ###### #####",
            otherCardMask: "#### #### #### ####",
            cardNumberTemp: "",
            isCardFlipped: false,
            focusElementStyle: null,
            isInputFocused: false,
            errorMessage: "",
            valorAdesao: 0,
            valorTotal: 0,
            valorParcela: 0,
            cotacao_pago: 0,
            data_vencimento: "",
            boletoLiberado: DefaultEnum.NAO,
            plano_valor_id: "",
            qrCodeImage: "",
            qrCodeGerado: "",
            chavePix: "",
            pagadorId: "",
            expiracaoPix: process.env.VUE_APP_PIX_EXPIRACAO_SEGUNDOS,
            mostrarOpcoes: DefaultEnum.SIM,
            tentouPagamento: false,
            verificandoPagamento: DefaultEnum.NAO,
            linkBoleto: null,
            identificationTypes: [],
            transacaoId: null,
            dadosPagamento: {
                tituloMetodo: "",
                forma: "",
                valorPago: 0,
                parcelas: this.parcelas ?? null,
                descricaoPagamento: ""
            }
        };
    },

    computed: {
        formatPix() {
            // Calcula os minutos e segundos
            const minutes = Math.floor(this.expiracaoPix / 60);
            const seconds = this.expiracaoPix % 60;
            // Retorna o tempo formatado
            return `${this.pad(minutes)}:${this.pad(seconds)}`;
        },
        getCardType() {
            let number = this.cardNumber;
            let re = new RegExp("^4");
            if (number.match(re) != null)
                return "visa";
            re = new RegExp("^(34|37)");
            if (number.match(re) != null)
                return "amex";
            re = new RegExp("^5[1-5]");
            if (number.match(re) != null)
                return "mastercard";
            re = new RegExp("^6011");
            if (number.match(re) != null)
                return "discover";
            re = new RegExp('^9792');
            if (number.match(re) != null)
                return 'troy';
            return "visa"; // default type
        },
        generateCardNumberMask() {
            return this.getCardType === "amex" ? this.amexCardMask : this.otherCardMask;
        },
        minCardMonth() {
            if (this.cardYear === this.minCardYear)
                return new Date().getMonth() + 1;
            return 1;
        }
    },

    watch: {
        cardYear() {
            if (this.cardMonth < this.minCardMonth) {
                this.cardMonth = "";
            }
        },
        PaymentSuccess(novoValor){
            if(novoValor == DefaultEnum.SIM){
                this.mostrarOpcoes  = DefaultEnum.NAO;
                this.stopLoading();
            }

            if(novoValor == DefaultEnum.NAO){
                this.mostrarOpcoes  = DefaultEnum.NAO;
                this.resetOpcoes();
                this.stopLoading();
            }
        },
        cotacao_pago(novoValor){
            if(novoValor == 1){
                clearInterval(this.intervalCotacao);
            }
        },
        dadosPagamento: {
            handler(val) {
                this.setPayment();
            },
            deep: true
        }
    },

    methods: {
        back() {
            this.clicked();
            this.setCurrentComp('cliente')
            this.$router.push({ path: `/cliente/${this.globCotacaoId}`});
        },
        nextpage() {
            this.clicked();

            setTimeout(() => {
                this.setCurrentComp('vistoria')
                this.$router.push({ path: `/vistoria/${this.globCotacaoId}`});
            }, 1000);
        },
        toggleOpcoes($event){
            const opcoesPagamento = {
                'cartao_credito': { paymentPix: DefaultEnum.NAO, paymentBill: DefaultEnum.NAO, paymentCreditCard: DefaultEnum.SIM },
                'pix': { paymentCreditCard: DefaultEnum.NAO, paymentBill: DefaultEnum.NAO, paymentPix: DefaultEnum.SIM },
                'boleto': { paymentPix: DefaultEnum.NAO, paymentCreditCard: DefaultEnum.NAO, paymentBill: DefaultEnum.SIM }
            };

            Object.assign(this, opcoesPagamento[$event]);
        },
        resetOpcoes(){
            this.paymentCreditCard  = false;
            this.paymentPix         = false;
            this.paymentBill        = false;
            this.tentouPagamento    = false;
            this.qrCodeImage        = "";
            this.qrCodeGerado       = "";
        },
        tentarNovoPagamento() {
            this.mostrarOpcoes  = DefaultEnum.SIM;
            this.PaymentSuccess = null;
            this.resetOpcoes();
        },
        flipCard(status) {
            this.isCardFlipped = status;
        },
        focusInput(e) {
            this.isInputFocused = true;
            let targetRef = e.target.dataset.ref;
            let target = this.$refs[targetRef];
            this.focusElementStyle = {
                width: `${target.offsetWidth}px`,
                height: `${target.offsetHeight}px`,
                transform: `translateX(${target.offsetLeft}px) translateY(${target.offsetTop}px)`
            };
        },
        blurInput() {
            let vm = this;
            setTimeout(() => {
                if (!vm.isInputFocused) {
                    vm.focusElementStyle = null;
                }
            }, 300);
            vm.isInputFocused = false;
        },
        verificaMeioPagamento(){
            if(this.$secureLS.get('ls_py').tituloMetodo != null){
                return this.$secureLS.get('ls_py').tituloMetodo;
            }

            return "";
        },

        setPayment(){
            let data = {
                'tituloMetodo': this.dadosPagamento.tituloMetodo,
                'forma': this.dadosPagamento.forma,
                'parcelas': this.dadosPagamento.parcelas,
                'valorPago': this.dadosPagamento.valorPago,
                'descricaoPagamento': this.dadosPagamento.descricaoPagamento,
            };

            this.$secureLS.set('ls_py', data);
        },

        verificaDataBoleto() {
            const selecionado = new Date(this.data_vencimento);
            const hoje = new Date();

            if (selecionado < hoje) {
                this.$swal.fire('Ops!', 'A data do boleto não pode ser menor que a data de hoje', 'warning');
                this.boletoLiberado = DefaultEnum.NAO;
                this.data_vencimento = null;
                return;
            } 

            if(selecionado > hoje.setDate(hoje.getDate() + 30)){
                this.$swal.fire('Ops!', 'A data do boleto não pode ser maior que 30 dias', 'warning');
                this.boletoLiberado = DefaultEnum.NAO;
                this.data_vencimento = null;
                return;
            }

            this.boletoLiberado = DefaultEnum.SIM;
            
        },

        pad(val) {
            // Adiciona um zero à esquerda se o valor for menor que 10
            return val < 10 ? '0' + val : val;
        },
        timerPix() {
            // Inicia o contador regressivo
            this.timer = setInterval(() => {
                if (this.expiracaoPix > 0) {
                    this.expiracaoPix--;
                } else {
                    this.$swal.fire('Opss!', 'O tempo para pagamento do PIX esgotou. Gere um novo QRCode.', 'warning');
                    this.resetOpcoes();
                    clearInterval(this.timer);
                }
            }, 1000);
        },

        dadosCartaoVazios(){
            return !this.cardName || !this.cardNumber || !this.cardCvv || !this.cardMonth || !this.cardYear;
        },

        dataVencimentoVazia(){
            return !this.data_vencimento;
        },

        async copiarChavePix(){
            try {
                await navigator.clipboard.writeText(atob(this.chavePix));
                this.$swal.fire('', 'Chave copiada com sucesso.', 'success');
            } catch($e) {
                this.$swal.fire('Opss!', 'Não foi possível copiar a chave. Tente novamente.', 'warning');
            }
        },

        async getCotacao() {
            const cotacao = await this.getDadosCotacao(this.globCotacaoId);

            if (cotacao) {
                this.valorAdesao = cotacao.protecao_adesao;
                this.plano_valor_id = cotacao.plano_valor_id;        
                return cotacao;
            }
        },
        async verificaPagamentoCotacao(){
            this.mostrarOpcoes          = DefaultEnum.NAO;
            this.verificandoPagamento   = DefaultEnum.SIM;

            let cotacao = await this.getCotacao();

            if(cotacao && cotacao.cotacao_pago === 1){
                this.mostrarOpcoes          = DefaultEnum.NAO;
                this.PaymentSuccess         = DefaultEnum.SIM;
                this.verificandoPagamento   = DefaultEnum.NAO;
                this.cotacao_pago           = cotacao.cotacao_pago;
            } else {
                this.mostrarOpcoes          = DefaultEnum.SIM;
                this.verificandoPagamento   = DefaultEnum.NAO;
            }

        },

        async getMeioPagamento(){
            await this.$http.get(process.env.VUE_APP_DEFAULT_URL + 'api/v1/pagamento/meio-pagamento')
            .then((response) => {
                this.meioPagamento = response.data.data[0];
                // 
                setTimeout(() => {
                    this.stopLoading();
                }, 3000);
                
            }).catch((err) => {
                this.stopLoading();
                this.mostrarOpcoes = DefaultEnum.SIM;
                this.$swal.fire('Opss!', 'Ocorreu um erro inesperado com o meio de pagamento. Contate nosso suporte.', 'error');
            });
        },
        async pagamento($event){
            this.iniLoading();


            switch(this.meioPagamento){
                // INTEGRAPAY
                case process.env.VUE_APP_INTEGRAPAY:
                    if(this.paymentPix === DefaultEnum.SIM){
                        this.qrCodeImage = null;
                        this.pagamentoPixIntegra();
                        return;
                    }
                    if(this.paymentCreditCard == DefaultEnum.SIM){
                        if (this.dadosCartaoVazios()) {
                            this.$swal.fire('Opss!', 'Preencha todos os campos corretamente', 'warning');
                            this.stopLoading();
                            return;
                        }
                        this.pagamentoCartaoIntegra();
                    }
                    if(this.paymentBill == DefaultEnum.SIM){
                        if(this.dataVencimentoVazia()){
                            this.$swal.fire('Ops!', 'Selecione a data de vencimento do boleto.', 'warning');
                            this.boletoLiberado = DefaultEnum.NAO;
                            this.stopLoading();
                            return;
                        }
                        this.verificaDataBoleto();
                        this.pagamentoBoletoIntegra();
                    }
                break;

                // MERCADO PAGO
                case process.env.VUE_APP_MERCADO_PAGO:
                    this.createInstanciaMP();

                    if(this.paymentPix === DefaultEnum.SIM){

                        this.qrCodeImage = null;
                        this.pagamentoPixMP();
                        return;
                    }

                    if(this.paymentCreditCard == DefaultEnum.SIM){

                        if (this.dadosCartaoVazios()) {
                            this.$swal.fire('Opss!', 'Preencha todos os campos corretamente', 'warning');
                            this.stopLoading();
                            return;
                        }

                        this.pagamentoCartaoMP();
                    }

                    if(this.paymentBill == DefaultEnum.SIM){

                        if(this.dataVencimentoVazia()){
                            this.$swal.fire('Ops!', 'Selecione a data de vencimento do boleto.', 'warning');
                            this.boletoLiberado = DefaultEnum.NAO;
                            this.stopLoading();
                            return;
                        }
                        this.verificaDataBoleto();
                    }
                break;
            }
        },


        // INTEGRAPAY
        async registraTransacaoCartao(data){
            await this.$http.post(process.env.VUE_APP_DEFAULT_URL + 'api/v1/pagamento/integra/transacao/cartao-credito', data);
        },
        
        async pagadorIntegra(clienteId){
            await this.$http.post(process.env.VUE_APP_DEFAULT_URL + 'api/v1/pagamento/integra/pagador/' + clienteId)
            .then((response) => {
                const pagadorId = response.data.data.pagador_id;
                this.pagadorId = pagadorId;

                if (!pagadorId) {
                    this.$swal.fire('Opss!', 'Ocorreu um erro inesperado registrar seu número de pagamento. Tente novamente ou contate o suporte.', 'error');
                    return;
                }
            }).catch((err) => {
                if(err.response){
                    this.$swal.fire('Opss!', err.response.data.mensagem, 'warning');
                } else {
                    this.$swal.fire('Opss!', 'Revise suas informações pessoais e tente novamente.', 'warning');
                }
                this.PaymentSuccess = DefaultEnum.NAO;
                this.stopLoading();
            });
        },

        async pagamentoCartaoIntegra() {
            let data = {
                documento: this.cpf,
                celular: this.limpaString(this.celular.toString()),
                valor: this.valorAdesao,
                parcelas: this.parcelas,
                captura: true,
                cartao: {
                    nome: this.cardName,
                    numero: this.cardNumber,
                    cvv: this.cardCvv,
                    mes: this.cardMonth.toString(),
                    ano: this.cardYear.toString(),
                }
            };

            this.dadosPagamento.tituloMetodo = 'Cartão de crédito';
            this.dadosPagamento.forma = DefaultEnum.CARTAO_CREDITO;

            await this.$http.post(process.env.VUE_APP_INTEGRA_URL +  'simples/cartao', data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + process.env.VUE_APP_INTEGRA_TOKEN,
                },
            })
            .then((response) => {

                const registro = Object.freeze ({
                    parcelas: this.parcelas,
                    cliente_id: this.globClienteId,
                    cotacao_id: this.globCotacaoId,
                    valor: this.valorAdesao,
                    transacao_id: response.data.transaction_id,
                    status: response.data.status,
                    status_string: response.data.status_string,
                    response_envio: response
                });

                if (response.status === 201 && response.data.status === DefaultEnum.CARTAO_PAGO) {
                    this.PaymentId      = response.data.transaction_id; 
                    this.PaymentSuccess = DefaultEnum.SIM;
                    this.registraTransacaoCartao(registro);
                    this.stopLoading();
                }

                if (response.data.status != DefaultEnum.CARTAO_PAGO) {
                    this.registraTransacaoCartao(registro);
                    this.PaymentSuccess = DefaultEnum.NAO;
                    this.$swal.fire('Opss!', 'Não foi possível fazer o pagamento. Confira seus dados e tente novamente.', 'error');
                    this.stopLoading();
                }

            }).catch((err) => {
                this.PaymentSuccess = DefaultEnum.NAO;
                this.stopLoading();
                this.$swal.fire('Opss!', 'Algo deu errado no seu pagamento. Tente novamente.', 'error');
            });
        },

        async pagamentoBoletoIntegra(){
            const data = Object.freeze({
                cliente_id: this.globClienteId,
                cotacao_id: this.globCotacaoId,
                valor: this.valorAdesao,
                data_vencimento: this.data_vencimento,
            });
            
            this.dadosPagamento.tituloMetodo = 'Boleto';
            this.dadosPagamento.forma = DefaultEnum.BOLETO;

            await this.$http.post(process.env.VUE_APP_DEFAULT_URL + 'api/v1/pagamento/integra/transacao/boleto', data)
                .then((response) => {
                const url = response.data.data.url;
                this.tentouPagamento = DefaultEnum.SIM;

                if (url !== null) {
                    this.linkBoleto     = response.data.url;
                    this.PaymentSuccess = null;
                    this.linkBoleto = response.data.url;
                    window.open(response.data.url, '_blank', 'noreferrer');
                }

                if (url == null || url == "") {
                    this.PaymentSuccess = DefaultEnum.NAO;
                    this.$swal.fire('Opss!', 'Não foi possível fazer o pagamento. Confira seus dados e tente novamente.', 'error');
                }

                this.stopLoading();

            }).catch((err) => {
                
                this.$swal.fire('Opss!', 'Ocorreu um erro inesperado. Verifique os dados e tente novamente.', 'warning');
                this.PaymentSuccess = DefaultEnum.NAO;
                this.stopLoading();
            });
        },

        async pagamentoPixIntegra(){
            const data = Object.freeze({ 
                "cliente_id": this.globClienteId,
                "valor": parseFloat(this.valorAdesao),
                "cotacao_id": this.globCotacaoId
            });

            this.dadosPagamento.tituloMetodo = 'Pix';
            this.dadosPagamento.forma = DefaultEnum.PIX;

            await this.$http.post(process.env.VUE_APP_DEFAULT_URL + 'api/v1/pagamento/integra/transacao/pix', data)
            .then((response) => {
                this.tentouPagamento = DefaultEnum.SIM;

                if(response.data){
                    const retorno      = response.data;

                    this.qrCodeImage    = retorno.data.pagamento_imagem; 
                    this.chavePix       = retorno.data.codigo;
                    this.expiracaoPix   = retorno.data.expiracao;
                    this.qrCodeGerado   = DefaultEnum.SIM;
                    this.PaymentSuccess = null;
                    this.timerPix();

                    // this.intervalVerificaPIX = setInterval(async() => {
                    //     this.verificaPIX(retorno.data.id);
                    // }, 10000);
                }

                this.stopLoading();

            }).catch((err) => {
                this.$swal.fire('Opss!', 'Não foi possível gerar o QRCode.', 'error');
                this.PaymentSuccess = null;
                this.qrCodeImage    = null;
                this.stopLoading();
            });
        },

        async verificaPIX(transaction_id){
            await this.$http.get(process.env.VUE_APP_INTEGRA_URL + 'pagamento-pix/' + transaction_id, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + process.env.VUE_APP_INTEGRA_TOKEN,
                },
            }).then((response) => {
                if(response.data){
                    this.PaymentSuccess = DefaultEnum.SIM;
                    clearInterval(intervalVerificaPIX);
                }
            }).catch((err) => {
                console.log('erro pix', err);
                this.PaymentSuccess = DefaultEnum.NAO;
            });
        },


        // MERCADO PAGO
        parcelaSelecionadaMP(){
            const parcela = this.parcelasMP[this.parcelaSelecionada];
            this.dadosPagamento.descricaoPagamento = parcela.recommended_message;
            this.valorTotal     = this.dadosPagamento.valorPago = parcela.total_amount;
            this.parcelas       = this.dadosPagamento.parcelas  = parcela.installments;
            this.valorParcela   = parcela.installment_amount;
        },
        
        async createInstanciaMP(){
            const script = document.createElement('script');
            script.src = 'https://sdk.mercadopago.com/js/v2';
            script.async = true;
            script.onload = () => {
                this.createInstanciaMP();
            };
            document.head.appendChild(script);

            this.instanciaMP = new window.MercadoPago(process.env.VUE_APP_MP_TOKEN);
            return this.instanciaMP;
        },

        async tokenCartaoMP(){

            return this.instanciaMP.createCardToken({
                'cardNumber': $('#paymentCreditCard').find('[name=cardNumber]').val().replace(/[^0-9]/g,''),
                'cardholderName': $('#paymentCreditCard').find('[name=cardName]').val(),
                'cardExpirationMonth': $('#paymentCreditCard').find('[name=cardMonth]').val(),
                'cardExpirationYear': $('#paymentCreditCard').find('[name=cardYear]').val(),
                'securityCode': $('#paymentCreditCard').find('[name=cardCvv]').val().replace(/[^0-9]/g,''),
            }).then((response) => {

                this.cardToken = response.id;
                return response.id;
            });
        },

        async pagamentoCartaoMP(){
            this.tentouPagamento = DefaultEnum.SIM;

            try {
                await this.tokenCartaoMP();

                let data = {
                    cotacao_id: this.globCotacaoId,
                    cliente_id: this.globClienteId,
                    valor: this.valorParcela,
                    parcelas: this.parcelas,
                    token: this.cardToken,
                    pagamento_metodo_id: this.PaymentMethodId,
                    nome_cartao: this.cardName,
                };

                this.dadosPagamento.tituloMetodo = 'Cartão de crédito';
                this.dadosPagamento.forma = DefaultEnum.CARTAO_CREDITO;

                await this.$http.post(process.env.VUE_APP_DEFAULT_URL +  'api/v1/pagamento/mercadopago/' + this.globClienteId + '/transacao/cartao-credito', data)
                .then((response) => {

                    if(response.status == 200){
                        this.transacaoId = response.data.data.id;
                    }

                })

            } catch(err) {
                this.PaymentSuccess = DefaultEnum.NAO;
                this.$swal.fire('Opss!', 'Ocorreu um erro inesperado com o seu pagamento. Revise as informações e tente novamente.', 'error');
            }
            
            this.verificaPagamentoMP(this.transacaoId, this.globCotacaoId);
        },

        async buscaParcelamento() {

            if(this.meioPagamento == process.env.VUE_APP_INTEGRAPAY) return;

            if( (this.cardNumber.replace(/\s/g, "")).length < 16 ) return;

            this.instanciaMP.getInstallments({
                'amount': this.valorAdesao.toString(),
                'locale': 'pt-BR',
                'bin': this.cardNumber.replace(/[^0-9]/g,''),
                'processingMode': 'aggregator'
            }).then((response) => {

                response.find((payment) => {

                    $('#paymentCreditCard').find('[name=parcela]').html('');

                    if (payment.payment_type_id != 'credit_card') {
                        return;
                    }

                    this.parcelasMP = payment.payer_costs;
                    this.PaymentTypeId = payment.payment_type_id;
                    this.PaymentMethodId = payment.payment_method_id;
                    
                })
            })
        },

        async verificaPagamentoMP(transacaoId, cotacaoId){
            if(transacaoId == null || cotacaoId == null){
                return;
            }

            await this.$http.post(`${process.env.VUE_APP_DEFAULT_URL}api/v1/pagamento/mercadopago/${transacaoId}/${cotacaoId}/verifica-pagamento`)
            .then((response) => {

                if(response.data.code == 200 && response.data.data.status == 1){
                    this.PaymentSuccess = DefaultEnum.SIM;
                }

            }).catch((err) => {
                this.PaymentSuccess = DefaultEnum.NAO;
                this.$swal.fire('Opss!', 'Algo deu errado com seu pagamento. Tente novamente.', 'error');
            });
        },

        async pagamentoPixMP(){
            this.tentouPagamento = DefaultEnum.SIM;

            let data = {
                cotacao_id: this.globCotacaoId,
                valor: this.valorAdesao,
            };

            this.dadosPagamento.tituloMetodo = 'PIX';
            this.dadosPagamento.forma = DefaultEnum.PIX;

            await this.$http.post(`${process.env.VUE_APP_DEFAULT_URL}api/v1/pagamento/mercadopago/${this.globClienteId}/transacao/pix`, data)
            .then((response) => {

                if(response.status == 200){
                    this.transacaoId    = response.data.data.id;
                    this.chavePix       = response.data.data.chave_pix;
                    this.qrCodeImage    = response.data.data.qr_code;
                    this.qrCodeGerado   = DefaultEnum.SIM;
                    this.mostrarOpcoes  = DefaultEnum.NAO;
                    this.timerPix();
                    this.stopLoading();
                }

            }).catch((err) => {
                this.PaymentSuccess = DefaultEnum.NAO;
                this.$swal.fire('Opss!', 'Algo deu errado com seu pagamento. Tente novamente.', 'error');
            });


            this.verificaPagamentoMP(this.transacaoId, this.globCotacaoId);
        },

        resetPayment(){
            this.PaymentSuccess = null;
            this.tentouPagamento = DefaultEnum.NAO;
        }

    },

    async mounted() {
        if(this.globCotacaoId && this.getIdUrl() && this.getIdUrl() != this.globCotacaoId){
            await this.verificacao('cotacao')
        } 

        if(!this.globCotacaoId){
            await this.verificaCotacaoComp()
            window.location.reload()
        }

        this.resetPayment();
        await this.getMeioPagamento();
        await this.verificaPagamentoCotacao();
        this.intervalCotacao = setInterval(async() => {
            const cotacao = await this.getCotacao();

            if(cotacao && cotacao.cotacao_pago === 1){
                this.PaymentSuccess = DefaultEnum.SIM;
                this.mostrarOpcoes  = DefaultEnum.NAO;
                this.cotacao_pago   = cotacao.cotacao_pago;
            }

        }, 50000);
        clearInterval(this.timer);
        this.setCurrentComp('pagamento')
        this.stopLoading()

        this.cardNumberTemp = this.otherCardMask;
        document.getElementById("cardNumber").focus();
    },
    beforeDestroy() {
      clearInterval(this.timer);
    }

}
</script>

<style scoped>

.pointer {
    cursor: pointer;
}
.card-form__col.-cvv {
    margin-bottom: 0;
}
.payment-card{
    width: 18rem; 
    height: 10rem; 
    cursor: pointer;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.valor-simbolo {
    margin-right: 5px;
    color: var(--cor-primaria);
    font-weight: bold;
}
.valor-plano {
    font-size: 20px;
    font-weight: bold;
}
.tente-novamente {
    background-color: rgb(216, 35, 35);
    height: 50px;
    border-radius: 30px;
    cursor: pointer;
}
.tente-novamente a {
    color: #FFF;
    font-weight: bold;
    font-size: 14px;
    text-decoration: none;
}
.pagamento-style{
    background: var(--cor-primaria);
    color: #FFF;
    font-weight: bold !important;
}
.pagamento-style i {
    color: #FFF;
    padding-right: 5px;
}
</style>